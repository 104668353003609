<template>
  <div>
    <c-table
      ref="table"
      title="화학자재 > 구성물질 > 규제항목 정량값"
      :gridHeight="gridHeight"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveQuantity"
            @btnCallback="saveQuantityCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='quantity'">
          <c-number-column
            :editable="editable"
            :col="col"
            :props="props"
            v-model="props.row[col.name]"
            @datachange="datachange(props)"
          />
        </template>
        <template v-else>
          <c-select
            v-if="props.row.appliStandQuantity && props.row.appliStandQuantity.length > 0"
            stype="tableselect"
            :editable="editable"
            :comboItems="comboItems(props.row)"
            type="none"
            itemText="codeName"
            itemValue="code"
            @datachange="val => changeSelect(val, props.row)" />
          <template v-else>
            참고할 사항 없음
          </template>
        </template>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chemQuantity',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
      }),
    },
    searchFlag: {
      type: Object,
      default: () => ({
        chemMsds: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemId' }
        ],
        columns: [
          {
            name: 'chemName',
            field: 'chemName',
            label: '구성물질(CAS No.)',
            align: 'left',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'billRegulName',
            field: 'billRegulName',
            label: '법규 - 규제항목',
            align: 'left',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'stand',
            field: 'stand',
            label: '기준',
            type: 'custom',
            child: [
              {
                name: 'quantity',
                field: 'quantity',
                label: '정량값',
                align: 'left',
                style: 'width:20%',
                sortable: true,
                type: 'custom'
              },
              {
                name: 'etc',
                field: 'etc',
                label: '참고',
                align: 'left',
                style: 'width:20%',
                sortable: true,
                type: 'custom'
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
    };
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 200) + 'px' : '500px'
    }
  },
  watch: {
    'searchFlag.chemMsds'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.quantity.list.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.quantity.save.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        mdmChemMaterialId: this.param.mdmChemMaterialId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    comboItems(row) {
      // 화학물질의 정량값이 있다는 가정
      let _comboItems = [];
      let _split = this.$_.split(row.appliStandQuantity, ', ');
      if (_split && _split.length > 0) {
        this.$_.forEach(_split, item => {
          let _idx = item.indexOf('(');
          _idx = _idx > -1 ? _idx : item.length
          let _code = item.substr(0, _idx);
          if (isNaN(_code)) return
          _comboItems.push({
            code: _code,
            codeName: item,
          })
        })
      }
      return _comboItems;
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    changeSelect(data, row) {
      /**
       * 선택한 값이 number text에 들어가게 처리
       */
      this.$set(row, 'quantity', data.value);
      if (row['editFlag'] !== 'C') {
        row['editFlag'] = 'U'
        row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    saveQuantity() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveQuantityCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
<style>
  .lawspan {
    color: #0072c6 !important;
    font-size: 13px !important;
    font-weight: 600;
  }
  .divchklaw {
    margin-bottom: 10px;
  }
</style>
